export const environment = {
  production: true,
  envName: 'prod',
  API_URL: window["env"]?.API_URL,
  STUDIO_API_URL: window["env"]?.STUDIO_API_URL,
  STUDIO_URL: window["env"]?.STUDIO_URL,
  BUILDER_PAYMENT_URL: window["env"]?.BUILDER_PAYMENT_URL,
  BUILDER_FEATURES_URL: window["env"]?.BUILDER_FEATURES_URL,
  USER_COOKIE: window["env"]?.USER_COOKIE,
  GUIDLINE_TOOTIP_COOKIE: window["env"]?.GUIDLINE_TOOTIP_COOKIE,
  enableSentry: window["env"]?.enableSentry,
  tracker_user: window["env"]?.tracker_user ,
  tracker_user_beta: window["env"]?.tracker_user_beta,
  API_TRACKER_URL: window["env"]?.API_TRACKER_URL,
  API_NOW_URL: window["env"]?.API_NOW_URL,
  TRACKER_STAGING_URL: window["env"]?.TRACKER_STAGING_URL,
  API_360_TOKEN: window["env"]?.API_360_TOKEN,
  API_360: window["env"]?.API_360,
  redirectionTracker: window["env"]?.redirectionTracker,
  bucket_document: window["env"]?.bucket_document,
  TRACKER_SECRET_KEY: window["env"]?.TRACKER_SECRET_KEY,
  base_Sketch: window["env"]?.base_Sketch,
  base_Sketch_bucket: window["env"]?.base_Sketch_bucket,
  API_360_KEY_NOTES: window["env"]?.API_360_KEY_NOTES,
  white_board_base_url: window["env"]?.white_board_base_url,
  whiteBoardClientId: window["env"]?.whiteBoardClientId,
  whiteBoardClientSecret: window["env"]?.whiteBoardClientSecret,
  nowUrl:window["env"]?.nowUrl,
  HIVE_URL :window ['env']?.HIVE_URL,
  X_FIGMA_TOKEN :window ['env']?.X_FIGMA_TOKEN,
  FIGMA_URL :window ['env']?.FIGMA_URL,
  apeiros :window ['env']?.apeiros,
};
