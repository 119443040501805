import { Constants } from '@shared/services/constants';
import {Injectable, ViewChild} from "@angular/core";
import { AsyncSubject, BehaviorSubject, Subject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { UiwDataServiceService } from "./uiw-data.service";
import { CommentModel } from "../models/comment.model";
import { RectangleComponent } from "@shared/components/manual-hotspot/rectangle-component/rectangle-component";
import { ManualHotspotService } from "@shared/components/manual-hotspot/manual-hotspot.service";
import { ToasterService } from '@core/toaster.service';
import {status, uiwConstants} from "@shared/services/uiw-Constants";
import { WindowRefService } from "@core/window-ref.service";
import { BaseService } from "@core/base.service";
import { environment } from "@env/environment";
import { CookieService } from "@core/cookie.service";
import { AuthService } from '@core/auth.service';
import { DataCommService } from './data-comm.service';

@Injectable({
    providedIn: 'root'
})
export class UiwPhaseService {
    isWheelEventDisabledOnPanZoom;
    currentProject;
    isCommentBoxOpen = {};
    currentProjectComments;
    commentsDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    singleCommentSelectSubject: BehaviorSubject<CommentModel> = new BehaviorSubject(null);
    commentModeSelectorSubject: Subject<number> = new Subject();
    getAllStoriesSubject: Subject<any> = new Subject();
    uploadedDesignUrlSubject: BehaviorSubject<string> = new BehaviorSubject('');
    getStoryEstimateSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    getClickPanzoomSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    storyMappingFromFlowChart: BehaviorSubject<any> = new BehaviorSubject(null);
    currentHotspotSelectedForEditing: BehaviorSubject<any> = new BehaviorSubject(null);
    clickableItemsList: BehaviorSubject<any> = new BehaviorSubject(null);
    selectSvgOnHotspotClick: Subject<any> = new Subject();
    recentChangesSubject: Subject<any> = new Subject();
    clickedOutSideSubject: Subject<any> = new Subject();
    savedEditedData;
    isEditMode;
    isCreateMode;
    selectedFeatureForHotspotEdit: Subject<any> = new Subject();
    callEditHotspotSubmit: Subject<boolean> = new Subject();
    editPositionOfHotspot: Subject<any> = new Subject();
    clickableItemsArray = [];
    featureResponse;
    updateHotspotData: BehaviorSubject<any> = new BehaviorSubject(null);
    createHotspotsUpdate: Subject<any> = new Subject();
    storyDetailing: BehaviorSubject<any> = new BehaviorSubject(null);
    countdownSubject: BehaviorSubject<string> = new BehaviorSubject('');
    sideBarWidth: string;
    flowChartWidth;
    flowChartLeft;
    leftFlowChartPos;
    briefBlockWidth;
    featureNotesBlockWidth;
    internalDocumentsBlockWidth;
    commentsNumberData = [];
    singleComment;
    updateReplyInComments: BehaviorSubject<any> = new BehaviorSubject(null);
    updateAttachmentsOnStoryDetail: BehaviorSubject<any> = new BehaviorSubject(null);
    updateView: BehaviorSubject<any> = new BehaviorSubject(null);
    getFrameData: BehaviorSubject<any> = new BehaviorSubject(null);
    featureIdForEditEta: BehaviorSubject<any> = new BehaviorSubject(null);
    showHotspotEditFeature: boolean;
    activateOverlay: boolean;
    onboardingSteps: number;
    storyOnboardingSteps: number;
    deleteAttachmentSubject = new Subject<any>();
    rectanglesLoaded: Subject<boolean> = new Subject();
    customHotspotSaveSubject: Subject<RectangleComponent> = new Subject();
    showHideDeletePopup: boolean;
    currentHotspotId;
    clickableItemToBeDeleted = 0 ;
    createHotspotSubject: Subject<any> = new Subject();
    featureVersions;
    versionHeroImg: Subject<string> = new Subject();
    fileUploadSubject: Subject<any> = new Subject();
    fileUploadErrorSubject: Subject<any> = new Subject();
    studioftrId;
    selectedFeature;
    updateStoryStatus: BehaviorSubject<any> = new BehaviorSubject(null);
    isRightPanelVisible: boolean = true;
    isSidebarCollapsed: boolean = false;
    canStoryBeFinished = {};
    zoomLevelOnStoryOnboarding: Subject<boolean> = new Subject();
    disablePanzoomOnSkip: Subject<boolean> = new Subject();
    updateSingleFeature: Subject<any> = new Subject();
    isComponentLoded = false;
    currentViewSelectedOnRightPanel:any;
    allStoriesData =[];   
    isStoriesDataLoaded:boolean =false;
    featureSubject: Subject<any> = new Subject();
    storyDetailRouteParams;
    storyDetailRouteState;
    uploadStoryId;
    attachmentsArray = [];
    attachmentsArraySubject: Subject<any> = new Subject();
    elapsedSeconds = 0;
    timerWidth;
    countDownInterval;
    countdownObj = {};
    fitToZoomOnCommentsPanel: Subject<boolean> = new Subject();
    storyDetailOnBoardingSteps : number;
    storyDetailstoryOnboardingSteps :number;
    featureResponseSuccess: Subject<boolean> = new Subject();
    customPrototypeFlag = false;
    isEditEstimateVisible = {};
    oldEstimate = {};
    etaInputModel = {};
    sourceScreenForHotspot: string;
    onboardingActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isWheelEventDisabledOnPanZoomDetail = true;
    baseSketchFiles = {};
    unlinkedFeatures =[];
    toggleUnlink;
    toggleImageSync;
    isMappedForFirstTime;
    //Behaviour subjects are created for cases of status accepted to pass data of main story and right panel;
    rightSideMappingForSketch: BehaviorSubject<boolean> = new BehaviorSubject(null);
    leftSideMappingForSketch: BehaviorSubject<boolean> = new BehaviorSubject(null);
    notificationPanel = false;
    unreadCount = 0;
    notificationSource = new Subject();
    notificationSource$ = this.notificationSource.asObservable();
    wheelDisabled = new Subject();
    wheelDisabled$ = this.wheelDisabled.asObservable();
    clickedNotification :any={};
    notificationSubscribtion: BehaviorSubject<boolean> = new BehaviorSubject(null);
    fitToZoom = false;
    //mentions data
    showMentions =false;
    allMentions =[];
    // comment filter
    showFilters =false;
    showSubFilters= false;
    filtersArrayData=[
        {id:1,class:'member',text:'Commented by',selected:false,type:'mentions'},
        {id:2,class:'features',text:'Feature',selected:false,type:'features'},
        {id:3,class:'story',text:'Story',selected:false,type:'stories'}
    ]
    filtersCloneArrayData=[
        {id:1,class:'member',text:'Commented by',selected:false,type:'mentions'},
        {id:2,class:'features',text:'Feature',selected:false,type:'features'},
        {id:3,class:'story',text:'Story',selected:false,type:'stories'}
    ]
    clonedComments=[];
    allAppliedFilters = [];
    resolvedCheckbox = false;
    allFeatures = [];
    allStoryForFilter: any = [];
    clickedAllCheckBox:boolean =false;
    commentSubOnAdd: BehaviorSubject<boolean> = new BehaviorSubject(null);
    allMentionsForFilter =[];
    //all errors for sketch in comments panel
    commentErrorHeading=[
        {title: 'image_not_found_error',value: "Image not found or disabled for the following elements"}, 
        {title:'no_mapping',value: "No Mapping found for the following elements"}, 
        {title:'invalid_prefix_mapping',value: "Incorrect prefix for the following elements"}, 
        {title:'elements_error',value: "Mandatory Layers not found for the following elements"},
        {title:'mapping_error',value:"No Mapping found for the following elements"},
        {title:'sketch_layer_error',value:"Sketch Layer Error"},
        {title:'sketch_page_error',value:"Sketch Page Error"}
    ]
    
    showPopup =false;
    openArtBoardPopup =false;
    deletePopup= false;
    releaseNotes:any;
    scheduleMaintenance:any;
    showFeatureNotes = false;
    ApiCalledForReleaseNotes: boolean;
    showMaintenance: boolean =false;
    pastedHotSpot:any;
    // closePastePopup: boolean;
    copiedValue =0;
    highlightFeatureByStoryId: BehaviorSubject<number> = new BehaviorSubject(null);
    // sessionCountForCtrlZ = false;
    ctrlZ : BehaviorSubject<boolean> = new BehaviorSubject(false);
    // callCtrlZSub =false;
    commentError :any;
    showErrorPopup = false;
    //MERGINGTRACKER-17
    currentProjectPlatforms :any;
    selectedPlatformForTracker: any;
    isHotspotHorizontalScroll: boolean = false;
    isHotspotVerticalScroll: boolean = false;
    hotspotImageScrollX: number = 0;
    hotspotImageScrollY: number = 0;
    hotspotImageScale: number = 1;
    hotspotLinkingWrapperStyleObj: any = {};
    previewPerfectScrollBarConfig: any = {
        suppressScrollX: true,
        suppressScrollY: true
    };

    sketchFiles =[];
    sfIdNullFeature = [];
    subStoryPopup =false;
    featureInfoForSubStory :any;
    allFeatureForTitleMapping = [];
    allNotificationForPaltform =[];
    showDeallocatedPopup = false;
    deallocationText: string;
    previewHotspotHighlight: Subject<boolean> = new Subject();
    previewHotspotHighlight$: Observable<boolean> = this.previewHotspotHighlight.asObservable();
    showPreviewHotspotAnimation: boolean = true;
    tsId;
    isPreviewWebImgMoveToCenter: boolean = false;
    visual_qa_image: string;
    openVisualQAPopup: boolean;
    designReadyToSend: number = 0;
    isVisualQaExist: boolean;
    is_full_build: boolean;
    is_prototype:boolean;
    is_profressional:boolean;
    guid: any;
    studioFeatureBaseSketches: any = {}
    roles=[];
    selectedRole:any;
    visual_qa_err_msg: string = '';
    visual_qa_story_id: string = '';
    visual_qa_story_title: string = '';
    currentDevicePlatforms: any = {};
    mappingDone = false;
    mappingStory = new Subject();
    screenName: string = '';
    navigateToProjectsPage: boolean = false;
    isStartStoryConfirmationOverlay: boolean = false;
    startStoryConfirmation: Subject<any> = new Subject();
    designType: string = '';
    designTypeIconPath: string = '';
    figmaBoardUrl: string = '';
    isToggleStorySummaryPopup: boolean = false;
    storySummaryData: any;
    openConfirmationPopup: boolean = false;
    unlinkedPanelFigmaInfoTooltipStyle: any = {
        isVisible: false,
        left: 0,
        top: 0
    };
    allFeature: BehaviorSubject<any> = new BehaviorSubject(null);
    sprints :Array<any> = [];
    dimenson:Boolean;
    uploadSketch: BehaviorSubject<any> = new BehaviorSubject(null);
    storyOperationOverlay: Subject<any> = new Subject();
    openDlsUsagePopup: boolean = false;
    currentDlsUsage: any;
    isPlayPauseDisabled: boolean = false;
    storyType: string = '';

    constructor(private router: Router,
        private rectangleService: ManualHotspotService,
        private uiwDataService: UiwDataServiceService,
        private toasterService: ToasterService,
        private window: WindowRefService,
        public baseService: BaseService,
        public cookieService: CookieService,
        public authService :AuthService,
        private dataCommService: DataCommService
        ) { }


    getViewStatus(): Observable<any> {
        return this.updateView.asObservable();
    }

    setViewStatus(value: any) {
        this.updateView.next(value);
    }

    togglePanZoomWheelEvent(flag) {
        this.isWheelEventDisabledOnPanZoom = flag;
      //  this.wheelDisabled.next(true);
    }

    isUiWorkspace() {
        return this.router.url.includes('uiworkspace');
    }

    isUiWPreview() {
        return this.router.url.includes('preview');
    }

    isUiwBrief() {
        return this.router.url.includes('brief');
    }

    isFeatureNotes() {
        return this.router.url.includes('featureNotes');
    }

    isUiwHelp() {
        return this.router.url.includes('help') || this.router.url.includes('videoTutorials') ;
    }
    isWhiteBoard() {
        return this.router.url.includes('whiteboard');
    }

    isApeiros() {
        return this.router.url.includes('apeiros');
    }
    isInternalDocuments() {
        return this.router.url.includes('internalDocuments');
    }

    getStoryIdForMapping(): Observable<string> {
        return this.storyMappingFromFlowChart.asObservable();
    }

    setStoryIdForMapping(storyId: string) {
        this.storyMappingFromFlowChart.next(storyId);
    }

    getStoryDetail(): Observable<string> {
        return this.storyDetailing.asObservable();
    }

    setStoryDetail(storyDetail: string) {
        this.storyDetailing.next(storyDetail);
    }


    getCommentData(comment) {
        this.uiwDataService.getCommentData(this.currentProject.id, comment)
            .subscribe(
                (data: any) => {
                    this.singleCommentSelectSubject.next(data);
                },
                error => {
                    console.log(error);
                }
            );
    }

    getAttachments(): Observable<any> {
        return this.updateAttachmentsOnStoryDetail.asObservable();
    }

    setAttachments(attachment: any) {
        this.updateAttachmentsOnStoryDetail.next(attachment);
    }

    checkOnboardingActive() {
        const status = localStorage.getItem('onboarding') === 'true';
        return status;
    }

    goToNextOnboardingStep() {
        if (this.onboardingSteps === 13) {
            this.onboardingSteps = undefined;
            this.activateOverlay = false;
            localStorage.setItem('onboarding', 'false');
            localStorage.setItem('story_onboarding', 'true');
            this.disablePanzoomOnSkip.next(true);
        } else {
            if(this.onboardingSteps == 8){
                this.onboardingSteps += 2;

            }else {
                this.onboardingSteps += 1;
            }
        }
    }

    goToNextStoryOnboardingStep() {
        if (this.storyOnboardingSteps === 6) {
            this.storyOnboardingSteps = undefined;
            this.activateOverlay = false;
            localStorage.setItem('story_onboarding', 'false');
            this.disablePanzoomOnSkip.next(true);
        } else {
            this.storyOnboardingSteps += 1;
        }
    }

    goToNextOnboardingStepStoryDetail() {
        this.storyDetailOnBoardingSteps +=1;
    }

    skipOnboardingStoryDetail(event) {
        if (event) {
            event.stopPropagation();
        }
        localStorage.setItem('onboardingStoryDetailFirst', 'false');
        localStorage.setItem('story_onboardingDetailSecond', 'true');
        this.storyDetailOnBoardingSteps = undefined;
        this.activateOverlay = false;
        this.disablePanzoomOnSkip.next(true);
    }

    goToNextStoryOnboardingStepStoryDetail() {
        if(this.storyDetailstoryOnboardingSteps == 6){
       this.skipOnboardingStoryDetailInnerFlow();
        }else {
            this.storyDetailstoryOnboardingSteps += 1;
        }
    }
    skipOnboardingStoryDetailInnerFlow(event?) {
        if(event) {
            event.stopPropagation();
        }
        localStorage.setItem('onboardingStoryDetailFirst', 'false');
        localStorage.setItem('story_onboardingDetailSecond', 'false');
        this.storyDetailstoryOnboardingSteps = undefined;
        this.activateOverlay = false;
        this.disablePanzoomOnSkip.next(true);
    }

    skipStoryOnboarding(event) {
        if (event) {
            event.stopPropagation();
        }
        localStorage.setItem('story_onboarding', 'false');
        this.storyOnboardingSteps = undefined;
        this.activateOverlay = false;
        this.disablePanzoomOnSkip.next(true);
    }

    skipOnboarding(event) {
        if (event) {
            event.stopPropagation();
        }
        localStorage.setItem('onboarding', 'false');
        localStorage.setItem('story_onboarding', 'true');
        this.onboardingSteps = undefined;
        this.activateOverlay = false;
        this.disablePanzoomOnSkip.next(true);
    }

    resetOnboarding() {
        localStorage.removeItem('onboarding');
        localStorage.removeItem('story_onboarding');
        localStorage.removeItem('onboardingStoryDetailFirst');
        window.location.reload();
    }

    openHotspotDeletePopup(hotspotId, designerCreatedHotspot, clickableItemId) {
        this.currentHotspotId = hotspotId;
        this.clickableItemToBeDeleted = 0;
        if (!designerCreatedHotspot && clickableItemId) {
            this.clickableItemToBeDeleted = clickableItemId;
        }
        this.showHideDeletePopup = true;
    }

    closeHotspotDeletePopup() {
        this.showHideDeletePopup = false;
    }

    isChangeVersionView() {
        return this.router.url.indexOf('editversion') > -1;
    }

    getIdMappingForEditEta(): Observable<any> {
        return this.featureIdForEditEta.asObservable();
    }

    setIdMappingForEditEta(featureId: any) {
        this.featureIdForEditEta.next(featureId);
    }
    setAttachmentId(object: any) {
        this.deleteAttachmentSubject.next(object);
    }

    clearAttachmentId() {
        this.deleteAttachmentSubject.complete();
    }

    getAttachmentId(): Observable<any> {
        return this.deleteAttachmentSubject.asObservable();
    }

    getStoryStatus(): Observable<any> {
        return this.updateStoryStatus.asObservable();
    }
    setStoryStatus(status: any) {
        this.updateStoryStatus.next(status);
    }

    openRightPanel() {
        if (!this.isRightPanelVisible) {
            this.isRightPanelVisible = true;
        }
    }

    downloadSketchFile(event, url, trackEventObj?) {
        if (event) {
            event.stopPropagation();
        }
        if (trackEventObj?.cta === 'sampleSketchFile') {
            this.dataCommService.trackEvent(`sample_${this.designType.toLowerCase()}_files_downloaded`, {
                user_id: this.dataCommService.userUniqueid,
                buildcard_id: this.currentProject.build_card_id,
                feature_id: trackEventObj?.studioFeatureId,
                user_browser: this.dataCommService.userBrowser,
                user_device: this.dataCommService.userDevice
            });
        }
        window.open(
            url,
            '_blank'
        );
      //  window.location.href = url;
    }

    /**
     * Function to download the attachment files
     * @param {MouseEvent} event - MouseEvent
     * @param {string} refUrl - File Reference Url
     * @param {string} refName - File Reference Name
     */
    downloadAttachmentFiles(event: MouseEvent, refUrl: string, refName: string): void {
        if (event) {
            event.stopPropagation();
        }
        let xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open("GET", refUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            let urlCreator: any = window.URL || window.webkitURL,
                fileUrl: string = urlCreator.createObjectURL(this.response),
                tag: HTMLAnchorElement = document.createElement('a');
            tag.href = fileUrl;
            tag.download = refName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.onloadend = () => {
            this.toasterService.success('1 file downloaded');
        }
        xhr.send();
    }

    downloadFigmaPngFile(event, imgUrl) {
        if (event) {
            event.stopPropagation();
        }
        let temp: any = imgUrl.split('/'),
            imageName: string = temp[temp.length - 1],
            xhr = new XMLHttpRequest();
            
        xhr.open("GET", imgUrl, true);
        xhr.responseType = "blob";
        
        xhr.onload = function () {
            let urlCreator = window.URL || window.webkitURL,
                imageUrl = urlCreator.createObjectURL(this.response),
                tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = imageName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.send();
    }

    useBaseSketchFile(baseSketchUrl?) {
        this.dataCommService.trackEvent('base_sketch_file_downloaded' , {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            project_id: this.currentProject?.id
        });
        window.open(
            baseSketchUrl ? baseSketchUrl : 'https://bstudiouiwimages.blob.core.windows.net/base-sketch/Baseline.sketch',
            '_blank'
        );
    }

    filterFeature(feature) {
        if(feature){
            if (feature && feature.story && feature.story.wireframe){
                return null;
             }
             else{
                 let filteredFeature = feature.build_card_hero_images && feature.build_card_hero_images.find(e => e.is_primary);
                 if (filteredFeature) {
                     return filteredFeature;
                 }
             }
        }
    }

    inspirationFilterFeature(feature) {
        if (feature) {
            if (feature && feature.story && feature.story.wireframe) {
                return null;
            }
            else {
                if (feature?.base_sketch?.figma_image_url) {
                    return feature;
                }
            }
        }
    }

    showToastForAllocation(dateExist, from?) {
        this.navigateToProjectsPage = true;
        if (dateExist) {
            this.deallocationText = Constants.toasterMessage.PROJECT_DEALLOCATION_WITH_DATE.replace('{dateExist}', dateExist);
        }
        else if (from) {
            if (from === 'edit') this.deallocationText = Constants.toasterMessage.SKIP_HOTSPOT_MAPPING;
            else if (from === 'editScreenName') {
                this.navigateToProjectsPage = false;
                this.deallocationText = Constants.toasterMessage.SCREEN_NAME_CHANGE;
            };
            setTimeout(() => {
                this.clearText();
            }, Constants.clearTime);
        }
        else {
            this.deallocationText = Constants.toasterMessage.PROJECT_DEALLOCATION_WITHOUT_DATE;
        }
    }

    clearText(){
        this.showDeallocatedPopup =false;
        this.deallocationText ='';
    }

    isCustomPrototype(): boolean {
        return this.currentProject && this.currentProject.is_full_build === true && !this.customPrototypeFlag;
    }
    sketchUploadCase(story) {
        // storyStatusChange no change method is not in use
        // const mainHeroImage = this.filterFeature(item);
        if (story) {
            return (story.state == 'accepted' || (story.state == 'delivered' || story.state == 'accepted'))  && this.isCustomPrototype();
        }
        return false;
    }
    sketchUploadStoryList(item) {
        const mainHeroImage = this.filterFeature(item);
        if (item && mainHeroImage) {
            return  (item.state == 'accepted' || (mainHeroImage.status == 'approved' && (item.state == 'delivered' || item.state == 'accepted')))  && this.isCustomPrototype();
        }
        return false;
    }

    getNonPrimaryVariantsCount() {
        return this.attachmentsArray.length > 0 && this.attachmentsArray[0].build_card_hero_images.filter(img => !img.is_primary).length;
    }

    getAttachmentsCount() {
        return this.attachmentsArray.length > 0 && this.attachmentsArray[0].story && this.attachmentsArray[0].story.ordered_documents.filter(doc => !doc.is_primary && !doc.uiw_uploaded).length;
    }

    getSketchId() : Observable<any> {
        return this.rightSideMappingForSketch.asObservable();

     }

    setSketchId(storyIdForSketch) {
        this.rightSideMappingForSketch.next(storyIdForSketch);
     }

     getSketchIdForLeft() : Observable<any> {
        return this.leftSideMappingForSketch.asObservable();

     }

    setSketchIdForLeft(storyIdForSketch) {
        this.leftSideMappingForSketch.next(storyIdForSketch);
     }

     deviceMappedWithPlatform(platform) {
        // @ts-ignore
      return Object.keys(uiwConstants.devicePlatformMapping).find(device => {
          return  uiwConstants.devicePlatformMapping[device].find(plat => {
               if (plat == platform) {
                   return device;
               }
            });
        });


        // return Object.keys(uiwConstants.devicePlatformMapping).find(key => uiwConstants.devicePlatformMapping[key].find(platKey => platKey === platform));
     }
  //notification-- subjects
     getNotification(){
        return this.notificationSubscribtion.asObservable();
     }
     setNotification(value){
        this.notificationSubscribtion.next(value);
     }

    getAddCommentStatus(): Observable<any> {
        return this.commentSubOnAdd.asObservable();
    }

    setAddCommentStatus(value: any) {
        this.commentSubOnAdd.next(value);
    }
    closeFilter(){
        this.showSubFilters = false;
        this.showFilters = false;
    }
    isIpmExist(){
       return this.router.url.includes('ipm');
    }
    downloadActualWrapClass(item) {
        if (item) {
            const downloadSketchFlag = this.checkExistenceOfActualSketchInLocalStorage(item);
            if (item.story && item.story.id && item.story.client_sketch_id && (item.story.state === 'unscheduled' || item.story.state === 'unstarted') && !downloadSketchFlag) {
                return true;
            } else {
                return false;
            }
        }
    }
    checkExistenceOfActualSketchInLocalStorage(item) {
        let downloadSketchFlag = false;
        const getSketchLocalItem = JSON.parse(localStorage.getItem('downloadSketch'));
        if (getSketchLocalItem && getSketchLocalItem[this.currentProject.id]) {
                if (item.story && item.story.id) {
                        for (const story in getSketchLocalItem[this.currentProject.id]) {
                            if (story == item.story.id) {
                                downloadSketchFlag = true;
                            }
                        }
                }

        }
        return downloadSketchFlag;
    }
    objectLength(object) {
        let size = 0,
            key;
        for (key in object) {
            if (object.hasOwnProperty(key)) size++;
        }
        return size;
    }
    setDownloadActualSketchFlag(item) {
        let setSketchData = {};
        let getSketchLocalItem = [];

        if (!this.checkExistenceOfActualSketchInLocalStorage(item)) {
            getSketchLocalItem = JSON.parse(localStorage.getItem('downloadSketch'));
            setSketchData = Object.assign({}, getSketchLocalItem);

            if (this.objectLength(setSketchData[this.currentProject.id]) == 0) {

                setSketchData[this.currentProject.id] = {};
                setSketchData[this.currentProject.id][item.story.id] = 1;
            } else {
                if (setSketchData && setSketchData[this.currentProject.id]) {
                    for (let i = 0; i <= this.objectLength(setSketchData[this.currentProject.id]); i++){
                        if (!setSketchData[this.currentProject.id][item.story.id]) {
                            setSketchData[this.currentProject.id][item.story.id] = 1;
                        }
                    }
                }
            }
            localStorage.setItem('downloadSketch', JSON.stringify(setSketchData));
        }
    }
    removeStoryCookieOnFinish(storyId) {
        const getSketchLocalItem = JSON.parse(localStorage.getItem('downloadSketch'));
        let presentStory = false;
        if (getSketchLocalItem && getSketchLocalItem[this.currentProject.id]) {
            if (storyId) {
                for (const story in getSketchLocalItem[this.currentProject.id]) {
                    if (story == storyId) {
                      presentStory = true;
                    }
                }
            }
        }
        if (presentStory) {
            delete getSketchLocalItem[this.currentProject.id][storyId];
            localStorage.setItem('downloadSketch', JSON.stringify(getSketchLocalItem));
        }
    }
    openPdf(event){
        if (event) {
            event.stopPropagation();
        }
        if(this.designType === 'Figma') {
            window.open(uiwConstants.guildLineDoc.figma, '_blank');
        }
        else {
            window.open(uiwConstants.guildLineDoc.sketch, '_blank');
        }
    }

    
    toggleImageSyncFunc(event) {
        if (event) {
            event.stopPropagation();
        }
        if(this.toggleUnlink) this.toggleUnlink = false;
        this.toggleImageSync = !this.toggleImageSync;
        setTimeout(() => {
            if (!this.isRightPanelVisible) this.isRightPanelVisible = true;
        }, 100);
    }


    getFeatureNotes(){
        this.uiwDataService.maintenanceApi().subscribe((res:any)=>{
            if(res && res.release_notes){
              this.ApiCalledForReleaseNotes = true;
              this.showFeatureNotes =true;
              this.releaseNotes = res.release_notes;
              const obj={
                feature_release_items:this.releaseNotes.feature_release_items,
                improvement_release_items:this.releaseNotes.improvement_release_items,
                fix_release_items:this.releaseNotes.fix_release_items
              }
              this.releaseNotes.release_items= obj;
              delete this.releaseNotes.feature_release_items;
              delete this.releaseNotes.improvement_release_items;
              delete this.releaseNotes.fix_release_items;
      
            }
            if(res && res.schedule_maintenance){
              this.showMaintenance =true;
              this.scheduleMaintenance = res.schedule_maintenance;
            }
          })
    }

    // closeHotspotPastePopup(){
    //     this.closePastePopup =false;
    // }    
    getHighlightFeatureByStoryId(): Observable<any>{
        return this.highlightFeatureByStoryId.asObservable();
    }

    setHighlightFeatureByStoryId(featureId: number) {
        this.highlightFeatureByStoryId.next(featureId)
     }

    //  setCtrlZValue(value) {
    //     this.ctrlZ.next(value);
    //  }

    //  getCtrlZValue() : Observable<any> {
    //     return this.ctrlZ.asObservable();
    //  }

     getError(storyObj){
     let primaryDocument = this.getDocument(storyObj);
     let doc = primaryDocument.document;
     let allDocs = primaryDocument.allDocuments;
      if(doc && doc.sketch_document_id){
         let sketch = allDocs.find(e=> e.id == doc.sketch_document_id);
         if(sketch){
             return sketch.sketch_error;
         }
      }
     }

     getDocument(storyObj){
         let documents = storyObj.ordered_documents;
         if(document && documents?.length > 0){
          return {document:documents.find(d=> d.is_primary == true),allDocuments:documents};
         }
     }
     logOut(){
        localStorage.removeItem('role');
        this.window.nativeDocument.cookie = `${environment.tracker_user}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookieService.cookieDomain};`;
        this.window.nativeDocument.cookie = `${environment.tracker_user_beta}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookieService.cookieDomain};`;
        window.location.href = this.baseService.redirectionTracker;
     }
     
    getStoryOnBoarding() {
        if (this.storyOnboardingSteps > 0 && localStorage.getItem('story_onboarding') !== null) {
            return false;
        } else {
            return true;
        }
    }

    validateMobileSite(platform: string){
        if(platform && platform.includes('-')){
           return platform = platform.replace('-'," ");
        }
        return platform;
    }

    genericPlatformSetRefresh(currentProject){
         if(this.selectedPlatformForTracker == undefined){
            this.currentProjectPlatforms = currentProject.formatted_platforms;
            this.selectedPlatformForTracker = Object.values(this.currentProjectPlatforms)[0][0].name;
            Object.values(this.currentProjectPlatforms)[0][0]['selected'] = true;  
        }
        this.currentProject.device = this.deviceMappedWithPlatform(this.selectedPlatformForTracker);
        this.setRoleStatus();
    }
    //CATEGORY6-HOTSPOT
    checkForDoc(hotspot,frameData){
             if(hotspot.category_id == 6){
                if(frameData && frameData.story && frameData.story.ordered_documents.length > 0){
                       let is_Primary = frameData.story.ordered_documents.find(e => e.is_primary);
                       if(is_Primary){
                       const document = frameData.story.ordered_documents.find(e => e.content_type == 'application/octet-stream' && e.id == is_Primary.sketch_document_id);
                       if (document)  return false;  else  return true;  
                    }
                }
             }
             return true;
    }
    //CATEGORY6-HOTSPOT
   showDownLoadButton(featureData){
       if(featureData && !featureData?.story?.parent_id){
        let mainHeroImageObj = featureData.build_card_hero_images.find(e => e.is_primary ==  true);
        if(mainHeroImageObj && mainHeroImageObj.hotspots.length > 0) {
           let cat6 = mainHeroImageObj.hotspots.find(e=>e.category_id == 6);
            if(cat6){
                // console.log(cat6.clickable_items);
                return true;
            }
            else{
                return false;
            }
         }
         else{
             return false;
         }
       }
    }

    checkForVisualQA(feature,shouldOpenPopup){
    return ((feature && feature.story) ? this.checkPrimary(feature,shouldOpenPopup) : false);
    }

    checkPrimary(feature,shouldOpenPopup){
        let is_Primary = feature.story.ordered_documents.find(e => e.is_primary);
       return ((is_Primary) ? this.checkSketchDocument(is_Primary,feature,shouldOpenPopup) : false );
    }

    checkSketchDocument(primaryDoc,feature,shouldOpenPopup){
       const document = feature.story.ordered_documents.find(e => e.content_type == 'application/octet-stream' && e.id == primaryDoc.sketch_document_id);
      return ((document && document.visual_qa_story_id) ? this.openPopupForQA(document,shouldOpenPopup) : false);
    }

    openPopupForQA(document,shouldOpenPopup){
        if(shouldOpenPopup){
        this.openVisualQAPopup = true;
        this.visual_qa_err_msg = document.visual_qa_error;
        this.visual_qa_story_id = document.visual_qa_story_id;
        this.visual_qa_story_title = document.visual_qa_story_title;
        this.visual_qa_image = document.visual_qa_image_url;
        }
        return true;
    }

    closeOpenQAPopup(event?){
        if (event) {
            event.stopPropagation();
        }
        this.openVisualQAPopup = false;
        this.visual_qa_image = null;
    }

    noVisualQa(){
        this.isVisualQaExist =false;
    }

    filterPlatform(device){
        return uiwConstants.devicePlatformMapping[device].find(e=> e == this.selectedPlatformForTracker);
    }
        setPreviewHotspotHighlight(flag: boolean): void {
        this.previewHotspotHighlight.next(flag);
    }

    getPreviewHotspotHighlight(): Observable<boolean> {
        return this.previewHotspotHighlight$;
    }

    redirection(){
        this.router.navigateByUrl('uiworkspace/projects');
    }

    showToastTailor(){
        if(!this.is_full_build && this.is_prototype && !this.is_profressional) {
            this.toasterService.error('No design needed for this story');
            return true;
        }
        else {
            return false;
        }
    }

    getCommentMode(): Observable<Number> {  
        return this.commentModeSelectorSubject.asObservable();
    }
    
    getGuid(allUsers){
             let user = this.authService.userProfile?.trackerUserData?.user;
             allUsers.forEach(element => {
                if (element.membership.user.email == user.email) {
                    this.guid = element.membership.user.guid;
                 }
            });
     }

    getBaseSketches(projectId) {
        this.uiwDataService.getBaseSketches(projectId, this.selectedPlatformForTracker,this.getRoleStatus()).subscribe((res: any) => {
            this.studioFeatureBaseSketches = res.base_sketches;
        });
    }

    getRoleStatus(){
        let role;
        if (localStorage.getItem('role') && localStorage.getItem('role') !== 'undefined')
            role = JSON.parse(localStorage.getItem('role'));
        if(this.selectedRole){
            return this.selectedRole.studio_id;
        }
        else{
            if(role){
               return role.studio_id;
            }
        }
        return null;
     }

    setRoleStatus(studioFeatureId?): void {
        if (this.roles?.length === 0) return;

        const storedRoleStr = localStorage.getItem('role');

        if (studioFeatureId) {
            const role = this.roles.find(role => role?.id === studioFeatureId);
            if (role) {
                this.selectRole(role);
                localStorage.setItem('role', JSON.stringify(role));
            }
        }
        else if (storedRoleStr && storedRoleStr !== 'undefined') {
            const storedRole = JSON.parse(storedRoleStr),
                role = this.roles.find(role => role?.studio_id === storedRole?.studio_id && role?.name === storedRole?.name);
            if (role) this.selectRole(role);
        }
        
        if (!this.selectedRole) {
            this.selectRole(this.roles[0]);
            localStorage.setItem('role', JSON.stringify(this.selectedRole));
        }
    }

    selectRole(role: any): void {
        role.selected = true;
        this.selectedRole = role;
    }

    removeRole() {
        this.roles = [];
        localStorage.removeItem('role');
    }

    createCurrentDevicePlatform() {
        for (let device in this.currentProjectPlatforms) {
            if (device === 'Smartphone') {
                this.currentDevicePlatforms['mobile'] = this.currentProjectPlatforms[device];
                let selectedPlatform = this.currentDevicePlatforms['mobile'].filter(e => e.name == 'mobile site')[0];
                if (selectedPlatform) selectedPlatform.name = 'mobile-site';
            }
            else if (device === 'Desktop')
                this.currentDevicePlatforms['web'] = this.currentProjectPlatforms[device];
            else if(device === 'Watch')
                this.currentDevicePlatforms['watch'] = this.currentProjectPlatforms[device];
            else
                this.currentDevicePlatforms[device] = this.currentProjectPlatforms[device];
        }
    }

     swapDesigns(item, platform) { 
        let payload = {
            "build_card_id": this.currentProject.build_card_id,
            "platform1": this.selectedPlatformForTracker,
            "device1": this.currentProject.device,
            "platform2": platform,
            "device2": this.currentProject.device,
            "role_id": this.getRoleStatus()
        };
        this.uiwDataService.platformSwappApi(item.id, payload).subscribe(res => {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        });
    }

    getBaseSketch(mainFeature) {
        let imageUrl: string = '';
        if (this.designType === 'Sketch') imageUrl = mainFeature.base_sketch.image;
        else if (this.designType === 'Figma') imageUrl = mainFeature.base_sketch.figma_image_url;
        return imageUrl;
    }

    checkForBase(feature){
      if (this.designType === 'Sketch' && feature && feature.base_sketch && feature.base_sketch.file) return true;
      else if (this.designType === 'Figma' && feature && feature?.story && feature?.story?.figma_info && feature?.story?.figma_info?.node_id) return true;
      return false;
    }

    setMappingValue(value){
      this.mappingStory.next(value);
    }

    enableHotspotPage(mainFeature) {
        if(mainFeature && (!mainFeature.story || (mainFeature.story && mainFeature.story.ordered_documents && mainFeature.story.ordered_documents.length === 0))) return false;
        else return true;
    }

    disableLeftSubToolbarForDevs() {
        if (this.authService.isDeveloperRole()) {
            let ctas: any = document.querySelectorAll('.leftToolbar .selectPointer, .leftToolbar .commentSelector, .leftToolbar .copyAllScreen');
            for (let el of ctas) {
                el.classList.add('commDisableBtn');
            }
        }
    }

    downloadFile(fileUrl) {
        window.open(fileUrl, '_blank');
    }

    configureDesignType(designType: string, figmaFileKey: string) {
        this.designType = designType.toLowerCase() === 'figma' ? 'Figma' : 'Sketch';
        this.designTypeIconPath = designType.toLowerCase() === 'figma' ? 'upload_active_icon.svg' : 'upload_reupload_icon.svg';
        this.figmaBoardUrl = this.getFigmaBoardUrl(figmaFileKey);
    }

    isStoryLoaded(feature) {
        return feature && feature.story && feature.story.ordered_documents && feature.story.ordered_documents.length > 0;
    }

    getPrimaryAttachment(feature) {
        if (this.isStoryLoaded(feature) && feature.build_card_hero_images && feature.build_card_hero_images.length > 1) {
            return feature.build_card_hero_images.find(doc => doc.is_primary);
        }
        return null;
    }

    getFigmaBoardUrl(fileKey: string, nodeId?: string) {
        let nodeQueryParam: string = '';
        if (nodeId) nodeQueryParam = `?node-id=${nodeId}`;
        return environment.FIGMA_URL + Constants.apiEndPoints.figmaBoardUrl.replace('{file_key}', fileKey) + nodeQueryParam;
    }

    openCloseStorySummaryPopup(event, story?) {
        if (event) {
            event.stopPropagation();
        }
        this.isToggleStorySummaryPopup = !this.isToggleStorySummaryPopup;
        if (this.isToggleStorySummaryPopup) {
            this.storySummaryData = story;
        }
    }

    navigateToStoryDetailPage(subtask, feature) {
        if (this.isIpmExist()) {
            this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/ipm`], { queryParams: { story: subtask.id } });
        }
        else if (subtask.wireframe) {
            this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/${feature.studio_feature_id}`], { queryParams: { story: subtask.id, platform: this.selectedPlatformForTracker } });
        }
        else {
            this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/${feature.studio_feature_id}`], { queryParams: { story: subtask.id } });
        }
    }

    getAcceptedPrimarySketchUrl(orderedDocs) {
        let primaryImgDoc, sketchDoc;
        if (orderedDocs && orderedDocs.length > 0) {
            primaryImgDoc = orderedDocs.find(doc => doc.is_primary);
            if (primaryImgDoc) {
                sketchDoc = orderedDocs.find(doc => doc.content_type == 'application/octet-stream' && doc.id === primaryImgDoc?.sketch_document_id && doc.sketch_status === "sketch_accepted");
            }
        }
        return sketchDoc ? sketchDoc?.remote_url : false;
    }
    
    randomNumberGenerator() {
        let array = new Uint32Array(1);
        crypto.getRandomValues(array);
        return array[0] * Math.pow(2, -32);
    }

    blobCreationFromURL(inputURI, artBoardName) {
        let arr = inputURI.split(','),
            mime = inputURI.split(',')[0].split(':')[1].split(';')[0],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], `${artBoardName}.png`, {
            type: mime
        });
    }
    
    getAllFeature(): Observable<any> {
        return this.allFeature.asObservable();
    }

    setAllFeature(features: any) {
        this.allFeature.next(features);
    }

    setStoryForFilters(allStory){
            if (allStory.length > 0) {
                this.allStoryForFilter= [];
                allStory.forEach(element => {
                    let obj = {
                        id: element.id,
                        name: element.title,
                        builder_feature_id: element.builder_feature_id,
                        type: 'stories',
                        design_status: element.design_status,
                        owned_by_current_user: element.owned_by_current_user,
                        child_stories: element.child_stories
                    }
                    this.allStoryForFilter.push(obj);
                });
                const obj = {
                    name: 'All',
                    type: 'stories',
                    checked: false
                }
                this.allStoryForFilter.splice(0, 0, obj);
            }
    }

    /**
     * Get the pre-calculated image dimensions based on the story status i.e base sketch/figma or heroImage
     * @param {any} feature - Feature
     * @returns {dimensions_updated: boolean, height: number, width: number}
     */
    allDimensionMethod(feature: any): {
        dimensions_updated: boolean,
        height: number,
        width: number
    } {
        let dimensionObj: any = {
            height: 0,
            width: 0,
            dimensions_updated: false
        };
            dimensionObj = feature?.build_card_hero_images?.filter(heroImg => heroImg.is_primary)[0]?.hero_image_attributes;
        return {
            height: +dimensionObj?.height,
            width: +dimensionObj?.width,
            dimensions_updated: dimensionObj?.dimensions_updated
        };
    }

    isOpenDlsUsagePopup() {
        if (this.currentDlsUsage?.build_card_dls_usage > 0) this.openDlsUsagePopup = !this.openDlsUsagePopup;
    }
    
    onPlayPauseSound(feature) {
        const { story: { current_timer_activity } } = feature;
        const audio = new Audio();
        const sourceUrl = current_timer_activity ? '../../../../assets/uiw-assets/mouse-click-pause.mp3' : '../../../../assets/uiw-assets/mouse-click-play.mp3';
        
        audio.src = sourceUrl;
        audio.addEventListener('canplaythrough', () => {
            audio.play();
        });
    }
    
    setSprints(sprints){
        this.sprints = sprints;
    }


    gotoInfoPage(object, event, projectId) {
        event.stopPropagation();
        if (this.storyType !== 'me' || (this.storyType === 'me' && (this.authService.getTrackerLoggedInUser()?.user?.name === object?.designer_name))) {
            if (!this.activateOverlay) {
                this.attachmentsArray = [];
                this.isComponentLoded = true;
                if (object.is_ipm && !object.builder_feature_id) {
                    this.router.navigate([`uiworkspace/${projectId}/featureDetail/ipm`], { queryParams: { story: object.id } });
                }
                else if (object && object.wireframe) {
                    this.router.navigate([`uiworkspace/${this.currentProject.id}/featureDetail/${object.builder_feature_id}`], { queryParams: { story: object.id, platform: this.selectedPlatformForTracker } });
                }
                else {
                    this.router.navigate([`uiworkspace/${projectId}/featureDetail/${object.builder_feature_id}`], { queryParams: { story: object.id }, state: { subStory: true } });
                }
            }
        } else {
            return false;
        }
    }

    /**
     * This fn is using for get current sprints
     */
    getRunningSprints() {
        const allCurrentSprintIds = Object.values(this.sprints).filter(arrObj => arrObj.status === 'running').map(function (e) { return e.id });
        return allCurrentSprintIds;
    }

    /**
     * Function to get the file type of the attachment
     * @param {any} attachment - Attachment
     * @returns {string}
     */
    getFileType(attachment: any): string {
        let arr: any[] = attachment?.name?.split('.'),
            type: string
        if (arr?.length) type = arr[arr?.length - 1];
        return type;
    }

    /**
     * Function to get the icon type of the attachment based on the file type
     * @param {any} attachment - Attachment
     * @returns {string}
     */
    getIconType(attachment: any): string {
        switch (attachment?.type) {
            case 'sketch':
                return 'figmasketchicon';
            case 'doc':
                return 'documenticon';
            case 'zip':
            case 'rar':
                return 'zipicon';
            case 'jpg':
            case 'png':
                return 'imageicon';
            default:
                return 'documenticon';
        }
    }
}
