import { Injectable } from '@angular/core';
import { WindowRefService } from '@core/window-ref.service';
import { environment } from '@env/environment';
/**
 * Now cookie management service
 */
@Injectable({
  providedIn: 'root'
})
export class CookieService {
  public userCookie = null;
  public userCookieforBeta = null;
  public cookieDomain = null;

  constructor(private window: WindowRefService) { }

  public setCookieDomain() {
    const hostfromurl = window.location.hostname;
    this.userCookie = environment.USER_COOKIE;
    if (hostfromurl === 'localhost') {
      this.cookieDomain = 'localhost';
    } else {
      const separate = hostfromurl.split('.');
      separate.shift();
      const currentDomain = separate.join('.');
      this.cookieDomain = '.' + currentDomain;
    }
  }

  public setAllCookie() {
    this.window.nativeDocument.cookie =
      `${this.getCookie(environment.USER_COOKIE)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookieDomain};`;
  }
  public setTrackerUserCookie() {
    this.setCookieDomain();
    this.userCookie = environment.tracker_user;
    this.userCookieforBeta = environment.tracker_user_beta;
    if(!this.getCookie(this.userCookie) && !this.getCookie(this.userCookieforBeta)){
      window.location.href = environment.redirectionTracker;
    }
    // const userDetails = JSON.stringify({"user":{"id":4641,"provider":"email","uid":"neetu.mogha@builder.ai","name":"Neetu Mogha`","username":null,"initials":"NM","email":"neetu.mogha@builder.ai","skill":"UI/UX","role":"admin"},"designerLogin":true,"adminLogin":true,"access-token":"qugzfLxWlZOsvSeJmMngPg","uid":"neetu.mogha@builder.ai","client":"Js-gV4xYoUZPEdcUIeVlKA"});
    // if(!this.getCookie(this.userCookie) && !this.getCookie(this.userCookieforBeta)){
    //   window.location.href = environment.redirectionTracker;
    // }
    // const userDetails = JSON.stringify(
    //   {"user":{"id":3442,"provider":"email","uid":"ui07@yopmail.com","name":"Ui 07","username":null,"initials":"UI","email":"ui07@yopmail.com"},"designerLogin":true,"adminLogin":false,"access-token":"6wyuCvVhvL1zvaWx98rzJw","uid":"ui07@yopmail.com","client":"JH8dnfRV5UlF7TP0fJ5Wqg"}      );
    //   this.window.nativeDocument.cookie = `${this.userCookie}=${userDetails}; expires=2038-01-19 04:14:07; path=/; domain=${this.cookieDomain};`;
    // //
    // this.window.nativeDocument.cookie = `${this.userCookie}=${userDetails}; expires=2038-01-19 04:14:07; path=/; domain=${this.cookieDomain};`;
  }
         
  public setCookie(name: string, value: string, expiryDate: string = '2038-01-19 04:14:07', path: string = '') {
    this.window.nativeDocument.cookie = `${name}=${value};expires=${expiryDate};path=${path}`;
  }  

  public getCookie(name: string) {

    const regexp = new RegExp(name + '=([^;]+)');
    return regexp.exec(this.window.nativeDocument.cookie);
  }

  public setLocalStorage(name: string, value: string) {
    localStorage.setItem(name, value);
  }

  public getLocalStorage(name: string) {
    return localStorage.getItem(name);
  }

  public getCookieEmail(name: string) {
    const userCookieData = this.getCookie(name);
    if (userCookieData) {
      const userData = JSON.parse(userCookieData[1]);
      return userData.user.email;  
    } else {
      return false;
    }
  }
}
