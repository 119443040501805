import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '@core/toaster.service';
import { CookieService } from '@core/cookie.service';
import { environment } from '@env/environment';
import { WindowRefService } from '@core/window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userProfile = {
    isCPEUser: false,
    isClient: false,
    isDesigner: false,
    isUrlWithBCToken: false,
    isPlatformMobile: false,
    userData: null,
    trackerUserData: null
  };
  public authToken;
  public bcAuthTokenMobile;
  public bcAuthTokenWeb;
  public cutsotmPrototypeVersion = null;

  constructor(
    public cookieService: CookieService,
    public router: Router,
    public toaster: ToasterService,
    private windowRef: WindowRefService) {
  }

  isBranding(): boolean {

    return this.check_Url();
  }


  check_Url(): boolean {
    return this.router.url.includes('branding');
  }


  isCPERole(): boolean {
    const userData = this.getLoggedInUser();
    if (userData && userData.roles && userData.roles.toUpperCase() === 'CPE') {
      return true;
    } else {
      return false;
    }
  }

  isClientRole() {
    const userData = this.getLoggedInUser();
    if (userData && !userData.roles) {
      return true;
    } else {
      return false;
    }
  }

  isDesignerRole() {
    if (!this.isClientRole() && this.userProfile.isUrlWithBCToken) {
      return true;
    } else {
      return false;
    }
  }
  isLoggedInUser() {
    const userCookieData = this.cookieService.getCookie(environment.USER_COOKIE);
    if (userCookieData) {
      return true;
    } else {
      return false;
    }
  }

  getLoggedInUser() {
    const userCookieData = this.cookieService.getCookie(environment.USER_COOKIE);
    if (userCookieData) {
      return JSON.parse(userCookieData[1]);
    }
    return null;
  }

  getTrackerLoggedInUser() {
    const btCookieDataStag = this.cookieService.getCookie(environment.tracker_user);
    const btCookieDataBeta = this.cookieService.getCookie(environment.tracker_user_beta);
    let userObjectStaging = btCookieDataStag ? JSON.parse(btCookieDataStag[1]) : '';
    let userObjectBeta = btCookieDataBeta ? JSON.parse(btCookieDataBeta[1]) : '';
    if (userObjectStaging) {
      return this.returnUserObject(userObjectStaging);
    } else if (userObjectBeta) {
      return this.returnUserObject(userObjectBeta);
    } else {
      return null;
    }

  }

  returnUserObject(userObj) {
    if (userObj && userObj.user && userObj.user.initials) {
      userObj.user.initials = userObj.user.initials.charAt(0);
    }
    return userObj;
  }

  getStoredBCAuthToken(isPlatformMobile: boolean) {
    const bctokenname = this.getBCTokenName(isPlatformMobile);
    return this.cookieService.getLocalStorage(bctokenname);
  }

  getBCAuthToken() {
    const bctokenname = this.getBCTokenName(this.userProfile.isPlatformMobile);
    if (!this[bctokenname]) {
      const bcktokenname = this.getStoredBCAuthToken(this.userProfile.isPlatformMobile);
      if (bcktokenname && bcktokenname !== 'null') {
        this[bctokenname] = bcktokenname;
      } else {
        this[bctokenname] = null;
      }
    }
    return this[bctokenname];
  }

  setBCAuthToken(token: any, isPlatformMobile: boolean) {
    const bctokenname = this.getBCTokenName(isPlatformMobile);
    this[bctokenname] = token;
    this.cookieService.setLocalStorage(bctokenname, token);
  }

  getAuthToken() {
    return this.authToken;
  }

  setAuthToken(token: any) {
    this.authToken = token;
  }

  getBCTokenName(isPlatformMobile: boolean) {
    return isPlatformMobile ? 'bcAuthTokenMobile' : 'bcAuthTokenWeb';
  }

  isPlatformMobile() {
    if (this.windowRef.nativeWindow.location.href.indexOf('/mobile') !== -1) {
      return true;
    } else {
      return false;
    }
  }
  checkBCTokenInUrl() {
    const isPlatformMobile = this.userProfile.isPlatformMobile;
    let bcAuthToken = this.windowRef.getQueryStringValue('bcauthtoken');
    if (bcAuthToken) {
      this.userProfile.isUrlWithBCToken = true;
      this.setBCAuthToken(bcAuthToken, this.userProfile.isPlatformMobile);
    } else {
      bcAuthToken = this.getBCAuthToken();
      const bctokenname = this.getBCTokenName(isPlatformMobile);
      if (bcAuthToken) {
        this[bctokenname] = bcAuthToken;
        this.userProfile.isUrlWithBCToken = true;
      } else {
        this[bctokenname] = bcAuthToken;
      }
    }
  }

  setUserProfile() {
    this.userProfile.isPlatformMobile = this.isPlatformMobile();
    this.checkBCTokenInUrl();
    this.cookieService.setTrackerUserCookie();
    this.userProfile.isCPEUser = this.isCPERole();
    this.userProfile.isClient = this.isClientRole();
    this.userProfile.isDesigner = this.isDesignerRole();
    this.userProfile.userData = this.getLoggedInUser();
    this.userProfile.trackerUserData = this.getTrackerLoggedInUser();
    if(!this.userProfile.trackerUserData) {
        window.location.href = environment.redirectionTracker;
    }
/*    const userCookieData = this.cookieService.getCookie(environment.USER_COOKIE);
    if (userCookieData) {
      this.cookieService.setAllCookie();
      this.userProfile.userData = JSON.parse(userCookieData[1]);
    }*/
    this.cookieService.setTrackerUserCookie();
    if (this.userProfile.userData && this.userProfile.userData.authtoken.toString()) {
      this.setAuthToken(this.userProfile.userData.authtoken.toString());
    } else {
      this.setAuthToken('');
    }
  }

  isAdminRole() {
    const userData = this.cookieService.getCookie(environment.tracker_user);
    return userData ? JSON.parse(userData[1]).adminLogin : false;
  }

  isDeveloperRole() {
    const userData = this.cookieService.getCookie(environment.tracker_user);
    return userData && !JSON.parse(userData[1]).adminLogin && !JSON.parse(userData[1]).designerLogin ? true : false;
  }

}
